import React from "react";
import { map } from "lodash";
import { connect } from "react-redux";
import { getStyles } from "../store/selectors";
import Card from "../components/Card";
import AppContainer from "../components/AppContainer";
import withTranslation from "../hocs/withTranslation";

const AboutPage = (props) => {
  const { T, appStyles } = props;
  return (
    <AppContainer.Content appStyles={appStyles}>
      <Card appStyles={appStyles}>
        <Card.Title appStyles={appStyles}>
          {T("About {{business_name}}")}
        </Card.Title>
        <Card.Content appStyles={appStyles}>
          {map(T("{{about}}").split("\\n"), (line) => (
            <div style={{ marginTop: 15 }}>{line}</div>
          ))}
        </Card.Content>
      </Card>
    </AppContainer.Content>
  );
};

const mapStateToProps = (state, props) => {
  return {
    appStyles: getStyles(state, props),
  };
};

export default connect(mapStateToProps)(withTranslation(AboutPage));
